.wrapper-footer {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 30px;
    line-height: 120%;

    a:visited {
        color: white;
    }

    @media (max-width: 750px) {
        gap: 20px;
    }

    .footer-item {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }
}