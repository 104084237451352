.terms-of-use-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    margin-left: 25%;
    margin-top: 125px;

    @media (max-width: 750px) {
        width: 90%;
        margin-left: 5%;
        margin-top: 70px;
    }

    .title-label {
        margin-top: 50px;
        font-style: normal;
        font-weight: 800;
        font-size: 50px;
        line-height: 120%;

        @media (max-width: 750px) {
            margin-top: 34px;
            font-size: 30px;
        }
    }

    .page-content {
        display: flex;
        flex-direction: column;
        gap: 60px;
        margin-bottom: 10%;
        margin-top: 66px;
        font-size: 18px;

        @media (max-width: 750px) {
            font-size: 14px;
        }

        .desc {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            margin-bottom: 72px;
        }

        .term {
            position: relative;

            &::before {
                display: block;
                position: absolute;
                top: -40px;
                content: "";
                height: 1px;
                width: 100%;

                background-color: rgba(255, 255, 255, 0.5);
            }

            &-text {
                padding: 0 2%;
            }

            &-header {
                font-weight: bold;
                font-size: 16px;
            }

            &-bullet {
                display: flex;
                align-items: flex-start;

                img {
                    width: 13px;
                    height: 13px;
                    margin-top: 22px;
                    margin-right: 10px;
                }
            }
        }
    }
}