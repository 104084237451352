.tmh-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    @media (max-width: 750px) {
        gap: 12px;
    }

    .header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 100%;
        width: 254px;
        text-align: center;

        @media (max-width: 750px) {
            width: 180px;
            font-size: 30px;
        }
    }

    .header-logo {
        width: 96px;
        height: 96px;

        @media (max-width: 750px) {
            width: 61px;
            height: 61px;
        }
    }
}