.landing-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 95vh;

    .label-1 {
        margin-top: 50px;
        width: 292px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 134%;
        @media (max-width: 750px) {
            margin-top: 34px;
        }
    }

    .label-2 {
        margin-top: 28px;
        width: 262px;
        font-style: normal;
        font-weight: 800;
        font-size: 35px;
        line-height: 120%;
        @media (max-width: 750px) {
            margin-top: 11px;
        }
    }

    .platform-block {
        margin-top: 28px;
        margin-bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 292px;
        height: 270px;
        background: #0C0C0CCC;
        border: 1px solid #383838CC;
        backdrop-filter: blur(20px);
        border-radius: 10px;
        @media (max-width: 750px) {
            margin-top: 11px;
            margin-bottom: 100px;
        }

        @media (max-width: 50px) {
            margin-top: 11px;
            margin-bottom: 50px;
        }

        .platform-logo {
            background: none;
            border: none;
            width: 184px;
            height: 55px;
            margin-bottom: 11px;
            margin-top: 11px;
            cursor: pointer;
            &:disabled {
                cursor: default;
            }
        }

        .label-3 {
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            opacity: 0.8;
        }
    }
}